import axios from "axios";
import Cookies from "js-cookie";

import * as cookie from "../utils/cookie";

const ENVIROMENT_SETUP = () => {
  const api = axios.create({
    baseURL: "https://staging.api.solides.jobs/v2",
  });

  const { token } = Cookies.getJSON();

  api.interceptors.request.use(async (config) => {
    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log('err', error.response)
      if (error.response.status === 401) {
        cookie.set("token", "");
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export default ENVIROMENT_SETUP;
