import { useState, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import * as authActions from "../../store/modules/auth/actions";

import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import CompanyContext from "../../contexts/companyContext";

function Login() {
  const [email, setEmail] = useState("zin.valadares@solides.com.br");
  const [password, setPassword] = useState("a32658462");
  const [codeLinkedin, setCodeLinkedin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { getSlugCompany, getHostUrl } = useContext(CompanyContext);

  const dispatch = useDispatch();

  const handleSuccess = (data) => {
    setCodeLinkedin(data.code);
    setErrorMessage("");
    dispatch(authActions.signInLinkedin(data.code));
  };

  const handleFailure = (error) => {
    setCodeLinkedin("");
    setErrorMessage(error.errorMessage);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        dispatch(authActions.signIn(email, password));
      }}
    >
      {getSlugCompany} - {getHostUrl}
      <div>
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <div>
        <label>Senha</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <div>
        <input type="submit" value="Entrar" />
      </div>
      <div>
        <LinkedIn
          clientId="78pp350a2v5dvv"
          state="EGSQrt0T0kLEXVCt"
          scope="r_liteprofile r_emailaddress"
          onFailure={(error) => handleFailure(error)}
          onSuccess={(data) => handleSuccess(data)}
          redirectUri="https://spike.solides.jobs/auth/linkedin/callback"
        >
          <img
            src={linkedin}
            alt="Log in with Linked In"
            style={{ maxWidth: "180px" }}
          />
        </LinkedIn>
      </div>
      <div>
        {!codeLinkedin && <div>No code Linkedin</div>}
        {codeLinkedin && <div>code Linkedin: {codeLinkedin}</div>}
        {errorMessage && <div>{errorMessage}</div>}
      </div>
      <a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78pp350a2v5dvv&redirect_uri=https://spike.solides.jobs/auth/linkedin/callback&state=foobar&scope=r_emailaddress">
        meu linkedin
      </a>
    </form>
  );
}

const mapStateToProps = (state) => ({
  // company: state.company.data,
  // token: state.authentication.token,
});

export default connect(mapStateToProps)(Login);
