import { createContext, useContext, useEffect } from "react";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";

import * as cookie from "../utils/cookie";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { token, token_expiration, name, email, avatar } = Cookies.getJSON();

  useEffect(() => {
    if (token_expiration) {
      try {
        // decript demora muito, pensar em uma forma mais performatica
        const { expires } = jwt.verify(
          token_expiration,
          process.env.REACT_APP_PRIVATE_KEY
        );

        if (expires) {
          const ONE_HOUR = 60 * 60 * 1000; /* ms */
          const currentTime = new Date();
          const expiresTime = new Date(expires);
          const time = expiresTime - currentTime;

          // comparar o a diferença de horario aqui se for menor do que 1 hr renovar o token
          if (time < ONE_HOUR) {
            console.log("menor que 1 hr");
          } else {
            console.log("maior que 1 hr");
          }
        }
      } catch (error) {
        console.log("expiresDecrypt", error);

        cookie.set("token", "");
        cookie.set("token_expiration", "");
      }
    } else {
      cookie.set("token", "");
    }
  }, [token_expiration]);

  return (
    <AuthContext.Provider value={{ token, name, email, avatar }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}
