export function signIn(email, password) {
  return {
      type: "@auth/SIGN_IN_REQUEST",
      payload: { email, password },
  };
}

export function signInLinkedin(code) {
  return {
      type: "@auth/SIGN_IN_LINKEDIN_REQUEST",
      payload: { code },
  };
}

export function logout() {
  return {
      type: "@auth/LOGOUT_REQUEST",
  };
}