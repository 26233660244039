import API from "../../../config/API";

const api = API();

export const getCompany = async (slug) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/company/${slug}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
