import { call, all, put, takeLatest } from "redux-saga/effects";

import * as api from "./repository";

export function* getCompanySaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true },
    });

    const { data: company } = yield call(api.getCompany, payload);
    let hidden = false;

    if (Object.keys(company.data).length === 0) hidden = true;

    yield put({
      type: "@company/COMPANY_SUCCESS",
      payload: { data: company.data || {}, isHidden: hidden },
    });
  } catch (error) {
    yield put({
      type: "@company/COMPANY_HIDDEN",
    });
    yield put({
      type: "@company/COMPANY_FAILURE",
      payload: {
        error: "Empresa não localizada. Favor conferir URL acessada.",
      },
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false },
    });

    yield put({
      type: "@utilities/FIRST_APPLICATION_SUCCESS",
      payload: false,
    });
  }
}

export default all([takeLatest("@company/GET_COMPANY", getCompanySaga)]);
