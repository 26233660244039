import { useEffect, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import * as companyActions from "../../store/modules/company/action";

import CompanyContext from "../../contexts/companyContext";
import * as cookie from "../../utils/cookie";


function Vacancies({ company }) {
  const { getSlugCompany, getHostUrl } = useContext(CompanyContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companyActions.getCompany(getSlugCompany));
  }, [dispatch, getSlugCompany]);

  console.log('company', company)
  return (
    <div>
      Página de vagas: {getSlugCompany}

      <div>
        <a href={`https://${getHostUrl}/login`} onClick={() => {
          cookie.set("destination", `https://${getHostUrl}/perfilCandidate`)
        }}>Acessar Perfil </a>
      </div>
      <div>
        <a href={`https://${getHostUrl}/login`} onClick={() => {
          cookie.set("destination", `https://${getSlugCompany}.${getHostUrl}/vacancies`)
        }}>Banco de Talentos </a>
      </div>
      <div>
        <a href={`https://${getHostUrl}/login`} onClick={() => {
          cookie.set("destination", `https://${getHostUrl}/curriculum`)
        }}>Candidatar</a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  company: state.company.data,
});

export default connect(mapStateToProps)(Vacancies);
