import React from "react";
import { Redirect } from "react-router-dom";

const PrivateRoute = ({
  permission,
  component: Component,
  types = [],
  path,
  history,
  token,
  ...rest
}) => {
  const privateVerify = () => {
    if (history.location.search === "") {
      if (token) {
        return <Component {...rest} token={token} />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      if (token) {
        return <Component {...rest} token={token} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  };
  return privateVerify();
};

export default PrivateRoute;
