import API from "../../../config/API";
const api = API(true);

export const signIn = async (json) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/user/sign-in`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const signInLinkedin = async (json) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/user/sign-in-linkedin`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
