import React, { useContext } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

import { ConnectedRouter } from "connected-react-router";

import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Vacancies from "../pages/vacancies";
import AuthRoute from "./authRoute";
import PrivateRoute from "./privateRoute";
import CompanyContext from "../contexts/companyContext";

function AppRoutes({ history, token: newToken, permission }) {
  const { token } = Cookies.getJSON();
  const { getSlugCompany } = useContext(CompanyContext);

  return (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <Switch>
          <AuthRoute
            path="/login"
            token={token}
            component={Login}
            slug={getSlugCompany}
            history={history}
            permission={permission}
          />

          <PrivateRoute
            exact
            path="/"
            token={token}
            component={Dashboard}
            history={history}
            permission={permission}
          />

          {/* public routes here */}
          {getSlugCompany && <Route path="/vacancies" component={Vacancies} />}

          <Route path="/notFound" render={() => <h1>Not Found</h1>} />
          <Redirect from='*' to='/notFound' />
        </Switch>
      </ConnectedRouter>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  permission: state.authentication.permissions,
  token: state.authentication.token,
});

export default connect(mapStateToProps)(AppRoutes);
