import Cookies from "js-cookie";

// const DOMAIN = "jobs.example.com";
const DOMAIN = "spike.solides.jobs";
const DEFAULT_OPTIONS = {
  path: "/",
  domain: DOMAIN,
  expires: 1, // 7 dias
  secure: true,
  SameSite: "None",
};

const get = (key) => Cookies.get(key);

const getJSON = () => Cookies.getJSON();

const remove = (key) => {
  const neededAttributes = {
    // Here you pass the same attributes that were used when the cookie was created
    // and are required when removing the cookie
    path: "/",
    domain: DOMAIN,
  };

  Cookies.remove(key, neededAttributes);
};

const set = (key, value, options = {}) =>
  Cookies.set(key, value, { ...DEFAULT_OPTIONS, ...options });

export { get, getJSON, set, remove };
