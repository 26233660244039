import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({
  component: Component,
  types = [],
  path,
  history,
  slug,
  token,
  ...rest
}) => {
  const slugVerify = () => {
    console.log(slug, token)
    if (token && !slug) {
      // redireciona para a rota de origen ou usuario ou fluxo de candidatura
      // precisa validar este fluxo
      return <Redirect to="/" />;
    } else if (!slug && !token) {
      return (
        <Route
          path={path}
          {...rest}
          render={() => <Component history={history} />}
        />
      );
    } else {
      return <Redirect to="/notFound" />;
    }
  };
  return slugVerify();
};

export default AuthRoute;
