import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { set } from "./utils/cookie";

import { getHost, getSlug } from "./utils/getHost";

import Routes from "./routes";
import CompanyContext from "./contexts/companyContext";
import { AuthProvider } from "./contexts/authContext";
import { store, persistor, history } from "./store";
import { useEffect, useState } from "react";

const App = () => {
  const [getHostUrl] = useState(getHost());
  const [getSlugCompany] = useState(getSlug());

  useEffect(() => {
    if (!getSlugCompany.includes(".")) {
      // pegar a rota
      set("origin", window.location.href);
    }
  }, [getSlugCompany]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <CompanyContext.Provider
            value={{
              getSlugCompany,
              getHostUrl,
            }}
          >
            <Routes history={history} />
          </CompanyContext.Provider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
