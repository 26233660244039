import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";

// import history from "../services/history";
import createStore from "./createStore";
import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({ sagaMonitor: null });
const _routerMiddleware = routerMiddleware(history);

const middlewares = [sagaMiddleware, _routerMiddleware];

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = createStore(persistedReducer, middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
