import { getSlug, getHost } from "../../utils/getHost";
import * as authActions from "../../store/modules/auth/actions";
import { useDispatch } from "react-redux";

import { useAuth } from "../../contexts/authContext";

function Dashboard() {
  const { token } = useAuth();

  const dispatch = useDispatch();

  return (
    <>
      Dashboard {getSlug()} - {getHost()}
      <br />
      {token}
      <div
        onClick={() => {
          dispatch(authActions.logout());
        }}
      >
        Sair
      </div>
    </>
  );
}

export default Dashboard;
