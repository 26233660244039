const INITIAL_STATE = {
  data: {},
  isHidden: false,
  error: "",
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@company/COMPANY_SUCCESS":
      return {
        ...state,
        data: action.payload.data,
        isHidden: action.payload.isHidden,
      };
    case "@company/COMPANY_HIDDEN":
      return {
        ...state,
        data: {},
        isHidden: true,
      };
    case "@company/COMPANY_FAILURE":
      return {
        ...state,
        error: action.payload.error,
        data: {},
      };
    default:
      return state;
  }
}
