import { call, all, takeLatest } from "redux-saga/effects";
import jwt from "jsonwebtoken";

import * as api from "./repository";

import * as cookie from "../../../utils/cookie";

function encryptTokenExpires(tokenExpiration) {
  const expireSeconds = tokenExpiration * 1000;
  const expires = new Date(new Date().getTime() + expireSeconds);
  // const crypt = new Cryptr(process.env.REACT_APP_CRYPT_KEY);
  // const expiresEncrypt = crypt.encrypt(expires);
  const data = jwt.sign({ expires }, process.env.REACT_APP_PRIVATE_KEY);
  cookie.set("token_expiration", data);
}

function* signInSaga({ payload }) {
  try {
    const { destination } = cookie.getJSON();
    const { data: response } = yield call(api.signIn, {
      user: payload.email,
      password: payload.password,
    });

    cookie.set("token", response.token);
    encryptTokenExpires(response.token_expiration);

    cookie.set("destination", "");
    window.location.href = destination ? destination : "/"; // perfil do candidato por padrão
  } catch (err) {
    console.log(err);
    cookie.set("token", "");
    // window.location.href = "/login";
  }
}

function* signInLinkedinSaga({ payload }) {
  try {
    const { destination } = cookie.getJSON();
    const { data: response } = yield call(api.signInLinkedin, {
      code: payload.code,
      redirectUri: "https://spike.solides.jobs/auth/linkedin/callback",
    });

    cookie.set("token", response.token);
    encryptTokenExpires(response.token_expiration);

    cookie.set("destination", "");
    window.location.href = destination ? destination : "/"; // validar os fluxos após o login
  } catch (err) {
    cookie.set("token", "");
    window.location.href = "/login";
  }
}

function logoutSaga() {
  try {
    cookie.set("token", "");
    cookie.set("token_expiration", "");

    window.location.href = "/login"; // validar os fluxos após o login
  } catch (err) {
    console.log("err", err);
    cookie.remove("token");
  }
}

export default all([
  takeLatest("@auth/SIGN_IN_REQUEST", signInSaga),
  takeLatest("@auth/SIGN_IN_LINKEDIN_REQUEST", signInLinkedinSaga),
  takeLatest("@auth/LOGOUT_REQUEST", logoutSaga),
]);
